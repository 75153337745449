






































































































































































































import { Component, Vue } from "vue-property-decorator";
import { CarouselItem } from "@/types/carousel";

@Component
export default class HomePage extends Vue {
    private carouselItems: CarouselItem[] = [
        {
            imageSrc: require("@/assets/images/HomePage/carousel1.jpg"),
            heading: "",
            subheading: "",
            button: null,
        },
        {
            imageSrc: require("@/assets/images/HomePage/carousel2.jpg"),
            heading: "",
            subheading: "",
            button: null,
        },
        {
            imageSrc: require("@/assets/images/HomePage/carousel3.jpg"),
            heading: "",
            subheading: "",
            button: null,
        },
        {
            imageSrc: require("@/assets/images/HomePage/carousel4.jpg"),
            heading: "",
            subheading: "",
            button: null,
        },
    ];
}
